
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.qa.vestiairecollective.com/a/fenx";
      
%line-styles {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  line-clamp: 1;
}

.productCard {
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
  max-width: 370px;
  padding: 0.75rem;

  &:hover {
    .productCard__image {
      &.sold {
        &:before {
          background: rgb(255 255 255 / 50%);
        }
      }
    }
  }

  @include respond-to($md-up) {
    padding: 1.5rem;
  }

  &__badge {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    max-width: calc(100% - 70px);

    &__icon {
      min-width: 0.6125rem;
    }

    &__text {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  &__topContainer {
    display: flex;
    position: relative;
    justify-content: flex-end;
    width: 100%;
    min-height: 32px;
    align-items: center;
    &--onlyLikeButton {
      justify-content: flex-end;
    }
  }
  &__imageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.375rem;

    @include respond-to($md-up) {
      margin-top: 1.5rem;
    }
  }
  &__image {
    width: 100%;
    max-width: 124px;
    position: relative;
    &.sold {
      &:before {
        content: '';
        background: rgb(255 255 255 / 80%);
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        z-index: 1;
        transition: background 0.3s ease-in-out;
      }
    }

    @include respond-to($md-up) {
      max-width: 190px;
      position: relative;
    }
  }
  &__image__soldText {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%);
    font-weight: 500;
    font-size: 1.125rem;
    text-transform: capitalize;
    text-align: center;
    white-space: nowrap;
    z-index: 2;
  }

  &__tagsContainer {
    display: flex;
    width: 100%;
    align-items: flex-start;
    gap: 0.5rem;
    margin: 1rem 0 0.5rem;
    min-height: 21px;
    flex-wrap: wrap;
  }
  // This might be a duplicate
  &__tag {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.09375rem 0.375rem;
    background-color: #f5f6f8;
    font-weight: 700;
    font-size: 10px;
    text-transform: uppercase;
    border: 1px solid #c4c4c4;
    @include respond-to($md-up) {
      padding: 0.1995rem 0.375rem 0.1195rem;
    }
  }

  &__productDetails {
    display: flex;
    flex-direction: column;
    gap: 0.125rem;
    flex-grow: 1;
    &--noProductTags {
      margin-top: calc(1.5rem + 21px);
    }

    &--semPdpIteration {
      position: relative;
    }

    &__likeButton {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  &__discountPercentage {
    background-color: $vc-color-g10;
    color: $vc-color-g100;
    padding: 0 0.25rem;
    font-weight: 500;
    font-size: 12px;
    margin-left: 0.25rem;
  }

  &__text {
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5;
    font-size: 0.875rem;
    white-space: nowrap;
    &--brand {
      text-transform: capitalize;
      font-weight: 700;
      @include respond-to($sm-down) {
        white-space: nowrap;
      }
    }
    &--name {
      color: #656565;
      // By using this trick, we can skip rendering empty <p> container for size
      &:last-child {
        margin-bottom: 1.4rem;
      }
    }

    &--size {
      font-size: 0.875rem;
      min-height: 1.1rem;
      @extend %line-styles;
    }

    &--new {
      font-size: 0.625rem;
      font-weight: 500;
      text-transform: uppercase;
      color: $vc-orange;
    }

    &--price {
      font-size: 0.875rem;
      font-weight: 700;
      display: block;
      margin-top: 0.25rem;
      line-height: 1.5;

      &--discount {
        color: #ff3b30;

        &--goodPriceVariant {
          color: $vc-color-g100;
        }
      }

      &--regularPrice {
        display: inline-block;
        margin-right: 0.3125rem;
        color: #282828;
        font-weight: 400;
        text-decoration: line-through;

        &--goodPriceVariant {
          color: $vc-color-g40;
        }
      }
    }
    &__location {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      margin-top: 0.5rem;
    }

    &__bottom {
      display: flex;
      align-items: center;
      margin-top: 0.5rem;
      min-height: 20px;

      &__location {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        margin-top: 0.5rem;
      }

      & > div {
        display: flex;
        align-items: center;
        gap: 0.25rem;
        @include respond-to($md-up) {
          gap: 0.45rem;
        }
      }

      &__ds,
      &__express,
      &__location {
        @extend %line-styles;
        white-space: nowrap;

        &__label {
          white-space: nowrap;
          @extend %line-styles;
        }
      }

      &__icon {
        display: inline-block;
        width: 1rem;
        height: 0.8125rem;
        color: $vc-color-black-off;
        line-height: 0;
        flex-shrink: 0;
        @include respond-to($md-up) {
          width: 1.125rem;
          height: 0.914rem;
        }
      }
    }
  }

  &__productLink {
    display: flex;
    flex-direction: column;
    gap: 0.125rem;
  }
}
